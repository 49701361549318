import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { getExtensionFile } from "../../../helpers/removeExtensionFile";
import Link from "../../lib/customLink";

export const ProductFeaturedModels = ({
  modelos,
}: {
  modelos: Queries.ProductosJson["modelos_destacados"];
}) => {
  return (
    <>
      {modelos && (
        <section className="product-section">
          {modelos.map((modelo, i) => (
            <div
              key={i}
              className={`flex flex-wrap justify-center mb-10 ${
                i % 2 !== 0 ? "flex-row-reverse" : ""
              }`}
            >
              <div className="flex flex-wrap justify-center basis-full md:basis-6/12 items-center">
                {modelo?.imagen.map((imagen, i) => {
                  const ext = getExtensionFile(imagen.base);

                  let Imagen: JSX.Element;

                  if (ext === "gif" || ext === "svg") {
                    Imagen = (
                      <img
                        src={imagen.publicURL!}
                        alt={imagen.base}
                        className="w-11/12 block mx-auto"
                      />
                    );
                  } else {
                    Imagen = (
                      <GatsbyImage
                        image={imagen.childImageSharp?.gatsbyImageData!}
                        alt={imagen.base}
                        className="w-11/12 block mx-auto"
                      />
                    );
                  }

                  return (
                    <div key={i} className="flex items-center">
                      {Imagen}
                    </div>
                  );
                })}
              </div>
              <div className="basis-full md:basis-6/12">
                <h2 className="text-center text-4xl font-bold text-aerotec-dark-10 lg:text-5xl">
                  {modelo?.nombre}
                </h2>
                {modelo?.descripcion && (
                  <p className="text-lg text-gray-800 my-4 text-center">
                    {modelo?.descripcion}
                  </p>
                )}
                <br />
                <ul className="flex w-full flex-wrap gap-4 text-center mb-6">
                  {modelo?.descripcion_lista.map((item, i) => (
                    <li
                      key={i}
                      className="px-6 py-2 rounded-lg border basis-full flex items-center text-gray-900"
                    >
                      <FaCheck className="mr-3 w-4 h-4 basis-1/12" />
                      <p className="text-base sm:text-lg basis-11/12">{item}</p>
                    </li>
                  ))}
                </ul>
                <Link
                  to="/contacto"
                  className="basis-full bg-pink-700 text-white py-3 px-8 rounded-lg flex justify-center items-center text-base sm:text-lg transition-colors duration-100 ease-out hover:bg-pink-800"
                  target="_blank"
                >
                  ¡Cotízalo ahora!
                </Link>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};
