import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const ProductSizeModels = ({
  modelos,
}: {
  modelos: Queries.ProductosJson["modelos_por_medida"];
}) => {
  return (
    <>
      {modelos && (
        <>
          <br />
          <section className="product-section">
            <h2 className="product-section-title">Modelos por medida</h2>
            <div className="flex flex-wrap gap-y-6 justify-center">
              {modelos.map((modelo, i) => (
                <div key={i} className="basis-1/2 md:basis-1/4">
                  <GatsbyImage
                    image={modelo?.imagen.childImageSharp?.gatsbyImageData!}
                    alt={modelo?.imagen.base!}
                    className="mx-auto w-11/12 block mb-4"
                  />
                  <p className="px-4 py-2 text-center text-gray-800 font-medium text-2xl">
                    {modelo?.medida}
                  </p>
                </div>
              ))}
            </div>
          </section>
        </>
      )}
    </>
  );
};
