import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

interface BannerProps {
  image: IGatsbyImageData;
  alt: string;
}

export const Banner = ({ image, alt }: BannerProps) => {
  return (
    <div className="w-full h-auto">
      <GatsbyImage
        image={image}
        alt={alt}
        className="w-full h-auto"
        objectFit="scale-down"
      />
    </div>
  );
};
