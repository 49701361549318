import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout/Layout";
import {
  Banner,
  ProductBackup,
  ProductDescription,
  ProductFeaturedModels,
  ProductModels,
  ProductSizeModels,
} from "../components/themed/ProductTemplate";
import { VendedoresButtons } from "../components/themed/VendedoresButtons";
import { Banner as InfHeBanner } from "../components/themed/inflablesHelioPage/Banner";

type QueryProductData = {
  products: Queries.ProductosJsonConnection;
};

export const query = graphql`
  query ($slug: String!) {
    products: allProductosJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          metadata {
            descripcion_pagina
            titulo_pagina
          }
          modelos {
            nombre
            imagen {
              childImageSharp {
                gatsbyImageData
              }
              base
            }
          }
          modelos_destacados {
            descripcion
            descripcion_lista
            nombre
            imagen {
              childImageSharp {
                gatsbyImageData
              }
              base
              absolutePath
              extension
              publicURL
              relativePath
            }
          }
          modelos_titulo
          modelos_por_medida {
            medida
            imagen {
              childImageSharp {
                gatsbyImageData
              }
              base
            }
          }
          respaldo {
            video
            imagenes {
              childImageSharp {
                gatsbyImageData
              }
              base
            }
          }
          titulo
          slug
          id
          banner {
            childImageSharp {
              gatsbyImageData
            }
            base
          }
          descripcion {
            descripcion
            description_lista
            link_tienda
            imagen {
              childImageSharp {
                gatsbyImageData
              }
              base
            }
          }
        }
      }
    }
  }
`;

const ProductPage = ({ data: result }: PageProps<QueryProductData>) => {
  const data = result.products.edges[0].node;

  return (
    <Layout
      navbar={{
        staticOpacity: "solid",
        staticColor: "blue",
      }}
    >
      <div className="pt-20">
        {data.slug === "inflables-de-helio" ? (
          <InfHeBanner
            image={data.banner.childImageSharp?.gatsbyImageData!}
            alt={`banner ${data.titulo}`}
          />
        ) : (
          <Banner
            image={data.banner.childImageSharp?.gatsbyImageData!}
            alt={`banner ${data.titulo}`}
          />
        )}
        <VendedoresButtons />
      </div>
      <ProductDescription titulo={data.titulo} descripcion={data.descripcion} />
      <ProductModels titulo={data.modelos_titulo} modelos={data.modelos} />
      <ProductSizeModels modelos={data.modelos_por_medida} />
      <ProductFeaturedModels modelos={data.modelos_destacados} />
      <ProductBackup
        imagenes={data.respaldo.imagenes}
        video={"https://www.youtube.com/embed/GYxLDl5g3S8"}
      />
    </Layout>
  );
};

export default ProductPage;
