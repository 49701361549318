import { IGatsbyImageData } from "gatsby-plugin-image";
import { removeExtensionFile } from "./removeExtensionFile";

export function findGatsbyImageByName(
  name: string,
  arr: Queries.FileEdge[]
): [img: IGatsbyImageData | undefined, imgs: Queries.FileEdge[]] {
  const img = arr.find((item) => removeExtensionFile(item.node.base) === name);

  const rest = arr.filter(
    (item) => removeExtensionFile(item.node.base) !== name
  );

  return [img?.node.childImageSharp?.gatsbyImageData, rest];
}
