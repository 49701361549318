import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaCheck, FaShoppingBag, FaShoppingCart } from "react-icons/fa";
import Link from "../../lib/customLink";

export const ProductDescription = ({
  descripcion,
  titulo,
}: {
  descripcion: Queries.ProductosJson["descripcion"];
  titulo: string;
}) => {
  return (
    <>
      {descripcion && (
        <section className="product-section">
          <div className="container flex flex-wrap justify-center">
            <h1 className="font-bold text-center text-4xl sm:text-6xl text-aerotec-dark-15 mb-4 basis-full md:mb-6 lg:text-7xl">
              {titulo}
            </h1>
            {descripcion.imagen && (
              <div className="mb-6 md:basis-6/12 lg:basis-5/12">
                <GatsbyImage
                  alt={descripcion.imagen.base}
                  image={descripcion.imagen.childImageSharp?.gatsbyImageData!}
                  className="w-8/12 mx-auto block md:w-10/12"
                />
              </div>
            )}
            <div
              className={`${
                descripcion.imagen
                  ? "md:basis-6/12 lg:basis-5/12"
                  : "basis-full"
              }`}
            >
              <p
                className={`text-slate-800 text-base sm:text-lg text-center mb-4   lg:leading-relaxed md:mt-4 ${
                  descripcion.imagen ? "lg:text-left lg:text-xl" : "lg:text-2xl"
                }`}
              >
                {descripcion.descripcion}
              </p>
              <br />
              {descripcion.description_lista && (
                <ul className="flex w-full flex-wrap gap-4 text-center">
                  {descripcion.description_lista.map((item, i) => (
                    <li
                      key={i}
                      className="px-6 py-2 rounded-lg border basis-full flex items-center text-slate-800"
                    >
                      <FaCheck className="mr-3 w-4 h-4 basis-1/12" />
                      <span className="text-base sm:text-lg basis-11/12">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              {descripcion.imagen && (
                <div className="flex justify-center mt-6 gap-y-2 flex-wrap">
                  <Link
                    to={descripcion.link_tienda || "www.aerotec.com.mx/tienda"}
                    target="_blank"
                    className="basis-full bg-aerotec text-white py-3 px-8 rounded-lg flex justify-center items-center text-lg transition-colors duration-100 ease-out hover:bg-aerotec-dark-10"
                  >
                    <FaShoppingCart className="mr-2 w-5 h-5" />
                    <span>Adquiérelo en línea</span>
                  </Link>
                  <Link
                    to={descripcion.link_tienda || "www.aerotec.com.mx/tienda"}
                    target="_blank"
                    className="basis-full bg-yellow-400 py-3 px-8 rounded-lg flex justify-center items-center text-lg transition-colors duration-100 ease-out hover:bg-yellow-500"
                  >
                    <FaShoppingBag className="mr-2 w-5 h-5" />
                    <span>Adquiérelo en línea</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
