import axios from "axios";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import { generateWhatsappLink } from "../../helpers/generateWhatsappLink";
import Link from "../lib/customLink";
import { VendedorProps } from "./contactPageComponents/types";

export const VendedoresButtons = () => {
  const data = useStaticQuery<{ vendedores: Queries.VendedoresJsonConnection }>(
    graphql`
      query {
        vendedores: allVendedoresJson {
          edges {
            node {
              id
              title
              phone
              name
            }
          }
        }
      }
    `
  );

  const [vendedoresData, setVendedoresData] = useState<
    {
      id: number;
      name: string;
      phone: string;
    }[]
  >([]);

  useEffect(() => {
    let isSubscribed = true;

    axios
      .get<{
        data: {
          id: number;
          nombre: string;
          apellido: string;
          telefono: string;
        }[];
      }>("https://api.grupoaerotec.com.mx/getPhones.php?type=ventas")
      .then((res) => {
        const data = res.data.data;
        const newVendedoresData = data.map((vendedor) => ({
          name: `${vendedor.nombre} ${vendedor.apellido}`,
          phone: formatPhoneNumber(vendedor.telefono),
          id: vendedor.id,
        }));

        if (isSubscribed) setVendedoresData(newVendedoresData);
      })
      .catch((err) => {
        setVendedoresData([]);
      });

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="flex flex-wrap justify-center gap-2 container my-4">
      {vendedoresData.map(({ id, name, phone }, i) => (
        <div key={i}>
          <Link
            to={generateWhatsappLink(phone, name)}
            key={id}
            className="flex items-center flex-shrink flex-grow basis-1/3 px-6 py-3 rounded-lg border boder hover:bg-slate-50"
            aria-label="Contactar vendedor(a) por Whatsapp"
            title="Contactar vendedor(a) por Whatsapp"
            target="_blank"
          >
            <div className="flex justify-end mr-2">
              <FaWhatsapp className="w-9 h-9 text-whatsapp-teal" />
            </div>
            <div className="text-whatsapp-teal text-left">
              <span className="block font-semibold text-sm">{name}</span>
              <span className="block text-xs">{phone}</span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};
