import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useLayoutEffect } from "react";
import { findGatsbyImageByName } from "../../../helpers/findGatsbyImageByName";

interface BannerProps {
  image: IGatsbyImageData;
  alt: string;
}

export const Banner = ({ image, alt }: BannerProps) => {
  const {
    banner_images: { edges: images },
  } = useStaticQuery<{ banner_images: Queries.FileConnection }>(graphql`
    query {
      banner_images: allFile(
        filter: { relativeDirectory: { eq: "productos/inflables-de-helio" } }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const [banner] = findGatsbyImageByName("banner", [...images]);
  const [nDirigible] = findGatsbyImageByName("dirigiblen_1", [...images]);
  const [dirigible] = findGatsbyImageByName("dirigible_1", [...images]);
  const [esfera] = findGatsbyImageByName("esfera", [...images]);

  useLayoutEffect(() => {
    gsap.to(".dirigible-naranja", {
      translateX: "-400%",
      duration: 44,
      yoyoEase: true,
      repeat: -1,
    });
    gsap.to(".dirigible-azul", {
      translateX: "600%",
      duration: 52,
      yoyoEase: true,
      repeat: -1,
    });

    const esferaTl = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });

    esferaTl
      .to(".esfera", {
        scale: 1.1,
        duration: 2.5,
        translateY: "-10px",
      })
      .to(".esfera", {
        scale: 1,
        duration: 2.5,
        translateX: "-10px",
      })
      .to(".esfera", {
        scale: 1.05,
        duration: 2.5,
        translateY: "10px",
      })
      .to(".esfera", {
        scale: 1,
        duration: 2.5,
        translateX: "0px",
      });
  }, []);

  return (
    <div className="w-full h-auto relative">
      <GatsbyImage
        image={banner!}
        alt={alt}
        className="w-full h-auto"
        objectFit="scale-down"
      />
      <div className="dirigible inflable-de-helio dirigible-naranja">
        <GatsbyImage image={nDirigible!} alt={`Dirigible naranja de helio`} />
      </div>
      <div className="dirigible inflable-de-helio dirigible-azul">
        <GatsbyImage image={dirigible!} alt={`Dirigible de helio`} />
      </div>
      <div className="esfera inflable-de-helio">
        <GatsbyImage image={esfera!} alt={`Esfera de Helio`} />
      </div>
    </div>
  );
};
