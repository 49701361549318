import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ContactForm } from "../contactForm";
import { Video } from "../video";

export const ProductBackup = ({
  imagenes,
  video,
}: {
  imagenes: Queries.ProductosJson["respaldo"]["imagenes"];
  video: Queries.ProductosJson["respaldo"]["video"];
}) => {
  return (
    <section className="product-section">
      <div className="mb-8">
        <h3 className="product-section-title">
          Tenemos 15 años de experiencia
        </h3>
        <p className="text-lg md:text-xl lg:text-2xl text-center text-gray-800">
          Somos fabricantes directos de Banderas en toda la República Mexicana,
          comprueba nuestra experiencia. Hemos realizado trabajos para más de
          1,000 diferentes clientes. ¿Qué esperas para ser parte de ellos?
        </p>
      </div>
      <div className="flex flex-wrap gap-y-6">
        {imagenes.map((imagen, i) => (
          <div key={i} className="basis-1/2 sm:basis-6/12 md:basis-3/12">
            <GatsbyImage
              alt={imagen?.base!}
              image={imagen?.childImageSharp?.gatsbyImageData!}
              className="w-11/12 block mx-auto rounded-lg"
            />
          </div>
        ))}
        <Video
          Tag="div"
          videoProps={{
            src: video,
            frameBorder: 0,
            className: "block rounded-lg w-full h-full",
          }}
          className="w-full mx-auto h-screen-1/3 mb-10 lg:h-screen-2/4"
        />
      </div>
      <div>
        <h5 className="text-center font-bold text-4xl text-aerotec-dark-15 mb-4">
          ¡Pónte en contacto con nosotros!
        </h5>
        <p className="text-lg text-center text-gray-800">
          Comunícate con nosotros y te haremos llegar la información que
          necesites.
        </p>
        <div className="flex justify-center">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};
