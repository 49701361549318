import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const ProductModels = ({
  modelos,
  titulo,
}: {
  titulo: Queries.ProductosJson["modelos_titulo"];
  modelos: Queries.ProductosJson["modelos"];
}) => {
  return (
    <>
      {modelos && (
        <section className="product-section">
          <h2 className="product-section-title">
            {titulo || "Nuestros Modelos"}
          </h2>
          <br />
          <div className="flex flex-wrap gap-4 justify-center">
            {modelos.map((modelo, i) => (
              <div key={i} className="flex-shrink flex-grow basis-5/12 md:basis-3/12 lg:basis-1/12">
                <GatsbyImage
                  alt={modelo?.imagen.base!}
                  image={modelo?.imagen.childImageSharp?.gatsbyImageData!}
                  className="rounded-t-lg"
                />
                <div className="px-4 py-2 text-center text-gray-800 font-medium text-2xl">
                  <h4>{modelo?.nombre}</h4>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
};
