import React from "react";

interface IVideoProps<el> {
  Tag: el;
  videoProps: React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  >;
}

class VideoWrapper<
  E extends keyof JSX.IntrinsicElements
> extends React.Component<IVideoProps<E>, any> {
  render() {
    const { Tag, videoProps, ...rest } = this.props;

    const VideoComponent = <iframe {...videoProps} />;

    const VideoWrapper = React.createElement(
      this.props.Tag,
      rest,
      VideoComponent
    );
    return <>{VideoWrapper}</>;
  }
}

export function Video<el extends keyof JSX.IntrinsicElements>({
  Tag,
  videoProps,
  ...wrapperProps
}: IVideoProps<el> & {
  className?: string;
}) {
  return (
    <>
      <VideoWrapper<el> Tag={Tag} {...wrapperProps} videoProps={videoProps} />
    </>
  );
}
